import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import { IconProps } from "./Icon";
import { AnimatedIcon } from "./AnimatedIcon";

const useStyles = makeStyles({
  svg: {
    fill: "none",
		stroke: "white",
		strokeWidth: 1.5
  }
});

const GraphSVG = () => (
  <>
    <line x1="11.999" y1="17.25" x2="11.999" y2="20.25" />
    <line x1="11.999" y1="20.25" x2="8.999" y2="23.25" />
    <line x1="11.999" y1="20.25" x2="14.999" y2="23.25" />
    <polyline points="4.499 9.75 7.499 9.75 8.999 6.75 11.999 12 14.249 8.25 16.499 9.75 19.499 6" />
    <rect x="1.499" y="0.75" width="21" height="16.5" rx="1.5" ry="1.5" />
  </>
);

export const GraphIcon = ({ animated }: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon className={classes.svg}>
			{animated && (
				<AnimatedIcon>
					<GraphSVG />
				</AnimatedIcon>
			)}
      {!animated && <GraphSVG />}
    </SvgIcon>
  );
};
