import React, { useRef, useEffect, useState, ReactNode } from "react";
import { animated, useSpring, AnimatedValue } from "react-spring";

type AnimatedIconProps = {
	children: ReactNode
}

export const AnimatedIcon = ({children}: AnimatedIconProps) => {
  const [offset, setOffset] = useState(0);
  const svgRef = useRef<SVGSVGElement>(null);
  const { dash, opacity } = useSpring<AnimatedValue<any>>({
    from: { opacity: 0, dash: offset },
    to: { opacity: 1, dash: 0 },
		reset: true,
		delay: 300,
		config: {
			duration: 1000
		},

  });

  useEffect(() => {
    if (svgRef.current !== null && !offset) {
      let pathLengths = Array.from(
        svgRef.current!.getElementsByTagName("*")
      ).map((el: SVGPathElement) => {
        let size;
        try {
          size = el.getTotalLength();
        } catch (error) {
          size = 0;
        }
				return size;
      });
			console.log(pathLengths);
      setOffset(Math.max(...pathLengths) + 0.1);
    }
  }, [offset]);
  return (
    <animated.svg
      ref={svgRef}
      style={{ opacity }}
      fill="none"
      stroke="white"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray={offset}
			strokeDashoffset={dash}
    >
      {children}
    </animated.svg>
  );
};
