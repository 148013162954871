import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import {
  makeStyles,
  Container,
  Typography,
  Box,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import "../styles/flickity.css";
import { Teaser } from "../components/Teaser";
import { TargetIcon } from "../components/icons/Target";
import { MagicianIcon } from "../components/icons/Magician";
import { GraphIcon } from "../components/icons/Graph";
import theme from "../components/theme";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";
import SEO from "../components/seo";

type IndexProps = {
  data: any;
};

SwiperCore.use([Navigation, Pagination]);

const useStyles = makeStyles({
  slide: {
    height: "100vh",
    width: "100vw",
    "& .swiper-pagination-bullet": {
      backgroundColor: "#fff",
      width: 14,
      height: 14,
    },
    [theme.breakpoints.down("sm")]: {
      "& .swiper-button-prev, & .swiper-button-next": {
        display: "none",
      },
    },
  },
  slideContent: {
    height: "100vh",
    maxWidth: "800px",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      margin: "0px 30px 0 100px",
    },
  },
  info: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom: 100,
    background:
      "linear-gradient(180deg, rgba(248,80,50,0) 0%, rgba(0,0,0,.8) 100%)",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  backdrop: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,.5)",
  },
  intro: {
    alignItems: "center",
    marginLeft: 0,
    "& > div": {
      marginTop: -200,
    },
    [theme.breakpoints.up("sm")]: {
    "& > div": {
      marginTop: 0,
    },
      paddingTop: "30px",
      paddingLeft: "200px",
      alignItems: "flex-start",
    },
  },
});
export default ({ data }: IndexProps) => {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Steuerberatung Tajdari" />
      <Swiper
        navigation
        loop={true}
        autoplay={false}
        direction="vertical"
        breakpoints={{
          640: {
            direction: "horizontal",
          },
        }}
        pagination={{ clickable: true }}
        className={classes.slide}
      >
        <SwiperSlide>
          <BackgroundImage fluid={data.albert.childImageSharp.fluid}>
            <div className={classes.backdrop}></div>
            <div style={{ position: "relative" }}>
              <Container>
                <Box
                  className={classes.slideContent}
                  color="primary.main"
                  style={{ alignItems: "flex-end" }}
                >
                  <div style={{ paddingBottom: "100px" }}>
                    <Typography variant="h3">
                      „Am schwersten auf der Welt zu verstehen ist die
                      Einkommensteuer.“
                    </Typography>
                    <Typography variant="subtitle1">Albert Einstein</Typography>
                    <Typography variant="subtitle2">
                      deutscher Physiker und Nobelpreisträger, geboren am 14.
                      März 1879 in Ulm;
                      <br />
                      verstorben am 18. April 1955 New Jersey, Vereinigte
                      Staaten
                    </Typography>
                  </div>
                </Box>
              </Container>
            </div>
          </BackgroundImage>
        </SwiperSlide>
        <SwiperSlide>
          <BackgroundImage fluid={data.brille.childImageSharp.fluid}>
            <div className={classes.backdrop}></div>
            <div style={{ position: "relative" }}>
              <Container>
                <Box
                  className={classes.slideContent}
                  color="primary.main"
                  style={{ alignItems: "flex-end" }}
                >
                  <div style={{ paddingBottom: "100px" }}>
                    <Typography variant="h3">
                      „Die Unkenntnis der Steuergesetze befreit nicht von der
                      Pflicht zum Steuerzahlen.
                      <br />
                      Die Kenntnis aber häufig.“
                    </Typography>
                    <Typography variant="subtitle1">
                      Mayer Amschel Rothschild
                    </Typography>
                    <Typography variant="subtitle2">
                      Kaufmann und Bankier, geboren am 23. Februar 1744 in
                      Frankfurt am Main;
                      <br /> verstorben am 19. September 1812 ebenda
                    </Typography>
                  </div>
                </Box>
              </Container>
            </div>
          </BackgroundImage>
        </SwiperSlide>
        <SwiperSlide>
          <BackgroundImage fluid={data.winston.childImageSharp.fluid}>
            <div className={classes.backdrop}></div>
            <div style={{ position: "relative" }}>
              <Container>
                <Box
                  className={classes.slideContent}
                  style={{ alignItems: "flex-end" }}
                  color="primary.main"
                >
                  <div style={{ paddingBottom: "100px" }}>
                    <Typography variant="h3">
                      „there is no such thing
                      <br /> as a good tax.“
                    </Typography>
                    <Typography variant="subtitle1">
                      Winston Churchill
                    </Typography>
                    <Typography variant="subtitle2">
                      britischer Staatsmann des 20. Jahrhunderts,
                      <br />
                      geboren am 30. November 1874 in Blenheim Palace,
                      Vereinigtes Königreich;
                      <br />
                      verstorben am 24. Januar 1965 Kensington, Vereinigtes
                      Königreich
                    </Typography>
                  </div>
                </Box>
              </Container>
            </div>
          </BackgroundImage>
        </SwiperSlide>
        <SwiperSlide>
          <BackgroundImage fluid={data.eule.childImageSharp.fluid}>
            <div style={{ position: "relative" }}>
              <Container>
                <Box
                  className={`${classes.slideContent} ${classes.intro}`}
                  color="primary.main"
                >
                  <div>
                    <Typography variant="h2">Steuerkanzlei Tajdari</Typography>
                    <Typography variant="h3">
                      Frankfurt am Main - Steuerberatung
                    </Typography>
                  </div>
                </Box>
              </Container>
              <div className={classes.info}>
                <Container>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <Teaser
                        headline="Durchsetzung Ihrer Interessen"
                        buttonText="Mehr erfahren"
                        icon={<TargetIcon animated />}
                      >
                        Wir analysieren Ihre individuelle Situation und stehend
                        beratend und handelnd an Ihrer Seite.
                      </Teaser>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Teaser
                        headline="Steueroptimale Gestaltung"
                        buttonText="Mehr erfahren"
                        icon={<GraphIcon animated />}
                      >
                        Identi­fizieren von Schwach­stellen, Reali­sierung
                        kluger und weit­sichtiger Maß­nahmen mit dem Ziel der
                        Optimierung.
                      </Teaser>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </BackgroundImage>
        </SwiperSlide>
      </Swiper>
    </Layout>
  );
};

export const query = graphql`
  query {
    brille: file(relativePath: { eq: "brille.jpg" }) {
      childImageSharp {
        fluid(
          quality: 100
          traceSVG: { background: "#e5e5e5", color: "#fff" }
        ) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    albert: file(relativePath: { eq: "albert.jpg" }) {
      childImageSharp {
        fluid(
          quality: 100
          traceSVG: { background: "#e5e5e5", color: "#fff" }
        ) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    winston: file(relativePath: { eq: "winston.jpg" }) {
      childImageSharp {
        fluid(
          quality: 100
          traceSVG: { background: "#e5e5e5", color: "#fff" }
        ) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    eule: file(relativePath: { eq: "eule.jpg" }) {
      childImageSharp {
        fluid(
          quality: 100
          traceSVG: { background: "#e5e5e5", color: "#fff" }
        ) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
