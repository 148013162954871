import React, { ReactNode, ReactChild } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		color: "#fff",
		margin: theme.spacing(2)
  },
  iconContainer: {
		marginBottom: 20,
    "& svg": {
      width: "5rem",
			height: "5rem"
    }
  },
  contentContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		textAlign: "center",
		height: "100%"
	},
	typography: {
		marginBottom: 20,
		maxWidth: 300
	}
}));

type TeaserProps = {
	icon?: ReactChild;
	headline?: string;
	buttonText?: string;
  children: ReactNode;
};

export const Teaser = ({ icon, headline, buttonText, children }: TeaserProps) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.iconContainer}>{icon}</div>
			<div className={classes.contentContainer}>
				<Typography className={classes.typography} variant="h5">
					{headline}
				</Typography>
				<Typography className={classes.typography} style={{flex: 1}}>
					{children}
				</Typography>
			</div>
    </div>
  );
};
