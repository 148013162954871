import React from "react";
import { SvgIcon, makeStyles } from "@material-ui/core";
import { IconProps } from "./Icon";
import { AnimatedIcon } from "./AnimatedIcon";

const useStyles = makeStyles({
  svg: {
    fill: "none",
    stroke: "white",
		strokeWidth: 1.5
  }
});

const TargetSVG = () => (
  <>
    <line x1="11.015" y1="11.859" x2="15.182" y2="7.692"/>
		<polygon points="15.182 7.692 15.75 3.942 18.998 0.75 19.5 3.374 22.124 3.876 18.932 7.124 15.182 7.692"/>
    <path d="M21.054,9.513A9.75,9.75,0,1,1,12.613,2.3"/>
    <path d="M16.721,10.737a5.251,5.251,0,1,1-5.1-3.987"/>
    <line x1="6.515" y1="20.25" x2="5.765" y2="23.25"/>
    <line x1="17.015" y1="20.25" x2="17.765" y2="23.25"/>
  </>
);

export const TargetIcon = ({ animated }: IconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon className={classes.svg}>
      {animated && (
        <AnimatedIcon>
          <TargetSVG />
        </AnimatedIcon>
      )}
      {!animated && <TargetSVG />}
    </SvgIcon>
  );
};
